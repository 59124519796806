import REQUIRED_PERMISSIONS from './requiredPermissions';

import config from '../../config';

const {
  USER_HOMEPAGE,
  SCRIPTS,
  STORYBOARDS,
  DAM,
  ORGANISATION,
  VIDEO_TOOL,
  INSPIRATIONS,
  CAST,
  CLIENT_STATISTICS_REPORTS
} = REQUIRED_PERMISSIONS;

export const topNavLinks = [
  config.HIDE_FEATURES.userHomepage
    ? null
    : {
        label: 'Home',
        url: '/home',
        icon: 'home',
        requiredPermission: USER_HOMEPAGE
      },
  {
    label: 'Pro',
    url: '/pro',
    icon: 'prodef',
    requiredPermission: VIDEO_TOOL
  },
  config.HIDE_FEATURES.hideStoryboardInClientNav
    ? null
    : {
        label: 'Storyboards',
        url: '/storyboards',
        icon: 'storyboard',
        requiredPermission: STORYBOARDS
      },
  {
    label: 'Cast',
    url: '/cast',
    icon: 'castdef',
    requiredPermission: CAST
  },
  /**
   * TODO: To include orgPackage and org permission
   * check for elevate at a later scope
   */
  {
    label: 'Elevate',
    url: '/elevate',
    icon: 'elevatedef',
    orgPackageRequired: false,
    requiredPermission: ''
  },
  {
    label: 'Cue',
    url: '/scripts',
    icon: 'cuedef',
    requiredPermission: SCRIPTS
  },
  {
    label: 'AI Discover',
    url: '/article-to-brief',
    icon: 'effects',
    requiredPermission: ''
  },
  {
    label: 'Media Library',
    url: '/medialibrary',
    icon: 'medialibrary',
    requiredPermission: DAM
  },
  {
    label: 'Inspirations',
    url: '/inspiration',
    icon: 'inspiration',
    requiredPermission: INSPIRATIONS
  },
  {
    label: 'Reports',
    url: '/reports',
    icon: 'financereports',
    requiredPermission: CLIENT_STATISTICS_REPORTS
  }
];

export const bottomNavLinks = [
  config.HIDE_FEATURES.bookings
    ? null
    : {
        label: 'Bookings',
        url: '/bookings',
        icon: 'bookingsdef',
        orgPackageRequired: true,
        onClick: () => window.open('/bookings', '_blank')
      },
  {
    label: 'Organisation',
    url: '/organisation',
    icon: 'company',
    requiredPermission: ORGANISATION
  },
  {
    label: 'Help Center',
    onClick: () => window.open('https://help.shootsta.com/en', '_blank'),
    icon: 'faq'
  },
  {
    label: 'Live Chat',
    onClick: () => {
      window.HubSpotConversations?.widget?.load();
      setTimeout(() => {
        window.HubSpotConversations?.widget?.open();
      }, 1000);
    },
    icon: 'hubspot'  
  }
];
