// @flow
import React, { ReactNode } from 'react';
import { FormModal, Text } from '@shootsta/common-react';
import type { RouterHistory } from '@shootsta/common-flow-types';
import FieldBlock from './FieldBlock';

import '../styles/_missing-fields-modal.scss';

type MissingField = {
  field: string,
  stage: number,
  visible: boolean,
  showActionButton: boolean,
  callback: Function
};

type MissingFieldsModalProps = {
  title: string,
  subtitle: string,
  onClose: Function,
  openPaymentsInvoiceModal: Function,
  visible: boolean,
  history: RouterHistory,
  missingFields: Array<MissingField>,
  footerText: string | ReactNode
};

export default function MissingFieldsModal({
  title = "You're Almost There",
  subtitle = "It looks like you haven't properly completed some of the steps.",
  visible,
  onClose,
  history,
  missingFields,
  footerText
}: MissingFieldsModalProps) {
  const missingFieldsMap = {
    name: {
      title: 'Title',
      subtitle: 'Please provide a name for your project template.'
    },
    allowedProjectTypes: {
      title: 'Project Template Details',
      subtitle: 'Please check that all mandatory fields have been filled in.'
    },
    customBriefs: {
      title: 'Custom Brief',
      subtitle: 'Please check that all mandatory fields have been filled in.'
    },
    additionalFields: {
      title: 'Additional Fields',
      subtitle: 'Please check that all mandatory fields have been filled in.'
    }
  };

  return (
    <FormModal
      visible={visible}
      onClose={() => onClose(false)}
      hideFooter
      title={title}
    >
      <div className="missing-fields-modal">
        <Text block>{subtitle}</Text>
        <div className="missing-fields-modal__fields">
          {missingFields &&
            missingFields.map(
              ({
                visible: fieldVisible,
                field,
                stage,
                callback,
                showActionButton
              }: MissingField) => (
                <FieldBlock
                  visible={fieldVisible}
                  onClose={onClose}
                  history={history}
                  stage={stage}
                  callback={callback}
                  showActionButton={showActionButton}
                  {...missingFieldsMap[field]}
                />
              )
            )}
        </div>
        {footerText && (
          <div className="missing-fields-modal__footer">
            <Text label noMargin="bottom">
              {footerText}
            </Text>
          </div>
        )}
      </div>
    </FormModal>
  );
}
